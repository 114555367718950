// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-shader-template-tsx": () => import("./../src/templates/ShaderTemplate.tsx" /* webpackChunkName: "component---src-templates-shader-template-tsx" */),
  "component---src-pages-cube-yama-tsx": () => import("./../src/pages/cube-yama.tsx" /* webpackChunkName: "component---src-pages-cube-yama-tsx" */),
  "component---src-pages-cube-tsx": () => import("./../src/pages/cube.tsx" /* webpackChunkName: "component---src-pages-cube-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-master-xiang-tsx": () => import("./../src/pages/master-xiang.tsx" /* webpackChunkName: "component---src-pages-master-xiang-tsx" */),
  "component---src-pages-random-rectangle-tsx": () => import("./../src/pages/random-rectangle.tsx" /* webpackChunkName: "component---src-pages-random-rectangle-tsx" */),
  "component---src-pages-react-three-fiber-test-tsx": () => import("./../src/pages/react-three-fiber-test.tsx" /* webpackChunkName: "component---src-pages-react-three-fiber-test-tsx" */),
  "component---src-pages-three-tsx": () => import("./../src/pages/three.tsx" /* webpackChunkName: "component---src-pages-three-tsx" */),
  "component---src-pages-triangle-2-tsx": () => import("./../src/pages/triangle-2.tsx" /* webpackChunkName: "component---src-pages-triangle-2-tsx" */),
  "component---src-pages-triangle-3-tsx": () => import("./../src/pages/triangle-3.tsx" /* webpackChunkName: "component---src-pages-triangle-3-tsx" */),
  "component---src-pages-triangle-4-tsx": () => import("./../src/pages/triangle-4.tsx" /* webpackChunkName: "component---src-pages-triangle-4-tsx" */),
  "component---src-pages-triangle-tsx": () => import("./../src/pages/triangle.tsx" /* webpackChunkName: "component---src-pages-triangle-tsx" */)
}

